export function getFormatDate (date, lang = 'es') {
  date = new Date(date)
  return date.toLocaleDateString(lang, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour12: false
  })
}

export function getFormatTime (date, {
  lessOneHour = 'Menos de una hora',
  someHours = 'Hace {num} horas',
  someDay = 'Hace 1 día',
  someDays = 'Hace {num} días'
} = {}) {
  const now = new Date().getTime()
  const diffTime = Math.abs(now - new Date(date).getTime())
  const diffDays = Math.trunc(Math.floor(diffTime / (1000 * 60 * 60 * 24))) 
  const diffHours = Math.trunc(diffTime / 36e5)
  if (diffHours < 1 ) {
    return lessOneHour
  } else if (diffHours < 24) { // meno de 1 dia
    return someHours.replace('{num}', String(diffHours))
  } else if(diffDays === 1) { // meno de una semana
    return someDay.replace('{num}', String(diffDays))
  } else if (diffDays >= 2 && diffDays < 15) {
    return someDays.replace('{num}', String(diffDays))
  }
  return ''
}
